:global(#app) {
  .button {
    background: transparent;
    border: none;
    cursor: pointer;
    display: inline-block;
    outline: none;
    padding: 0;
  }

  .initials {
    margin: 0 auto;
    text-transform: capitalize;
  }

  .wrapper {
    border-radius: 50%;
    color: #fff;
    display: inline-block;
    line-height: 1;
    text-align: center;
    vertical-align: top;
  }

  .wrapperHoverable:hover {
    opacity: 0.75;
  }

  /* Sizes */

  .wrapperTiny {
    font-size: 10px;
    font-weight: 400;
    height: 24px;
    line-height: 20px;
    padding: 2px 0;
    width: 24px;
  }

  .wrapperSmall {
    font-size: 12px;
    font-weight: 400;
    height: 28px;
    padding: 8px 0;
    width: 28px;
  }

  .wrapperMedium {
    font-size: 14px;
    font-weight: 500;
    height: 32px;
    padding: 10px 0;
    width: 32px;
  }

  .wrapperLarge {
    font-size: 14px;
    font-weight: 500;
    height: 36px;
    padding: 12px 0 10px;
    width: 36px;
  }

  .wrapperMassive {
    font-size: 36px;
    font-weight: 500;
    height: 100px;
    padding: 32px 0 10px;
    width: 100px;
  }

  /* Backgrounds */

  .backgroundEmerald {
    background: #2ecc71;
  }

  .backgroundPeterRiver {
    background: #3498db;
  }

  .backgroundWisteria {
    background: #8e44ad;
  }

  .backgroundCarrot {
    background: #e67e22;
  }

  .backgroundAlizarin {
    background: #e74c3c;
  }

  .backgroundTurquoise {
    background: #1abc9c;
  }

  .backgroundMidnightBlue {
    background: #2c3e50;
  }
}
